<template>

  <div id="page-user-list">

    <vx-card class="mb-base">
        <div class="flex justify-between">
            <div class="flex md:w-4/12">
                <v-select class="mr-2 md:w-6/12" :options="months" :clearable="false" v-model="month"></v-select>
                <v-select class="md:w-4/12" :options="years" :clearable="false" v-model="year"></v-select>
            </div>
            <vs-button @click="exportData">Download to Excel</vs-button>
        </div>
        <div class="my-4 flex align-center justify-center">
            <h4>Monthly Report</h4>
        </div>
        <vue-apex-charts type="bar" height="350" :options="graphicReport.options" :series="graphicReport.series"></vue-apex-charts>
    </vx-card>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import recipe from "@/recipe"

export default {
    components: {
        VueApexCharts
    },
    data() {
        return {
            loaded: false,
            graphicReport: {
                series: [],
                options: {}
            },
            month: {
                label: '',
                value: ''
            },
            months: [
                { label: 'Januari', value: '01' },
                { label: 'Februari', value: '02' },
                { label: 'Maret', value: '03' },
                { label: 'April', value: '04' },
                { label: 'Mei', value: '05' },
                { label: 'Juni', value: '06' },
                { label: 'Juli', value: '07' },
                { label: 'Agustus', value: '08' },
                { label: 'September', value: '09' },
                { label: 'Oktober', value: '10' },
                { label: 'November', value: '11' },
                { label: 'Desember', value: '12' }
            ],
            year: {
                label: '',
                value: ''
            },
            years: [],
        }
    },
    methods : {
        exportData () {
            let start_date  = `01-${this.month.value}-${this.year.value}`
            let end_date    = `31-${this.month.value}-${this.year.value}`
            window.open(`${recipe}/api/v1/technical/ticketing/trouble-ticket/export/${start_date}/${end_date}/excel`, '_blank');
        },
        getCurrentTime () {
            var months    = ['Januari','Februari','Maret','April','Mei','Juni','Juli','Agustus','September','Oktober','November','Desember']
            var now       = new Date()
            var thisMonth = months[now.getMonth()]
            var thisYear  = now.getFullYear()
            var min       = 2014;
            var max       = thisYear;

            for (var i = min; i <= max; i++) {
                this.years.push({
                label : i,
                value : i
                })
            }

            this.month = {
                label : thisMonth,
                value : now.getMonth() + 1
            }

            this.year = {
                label : thisYear,
                value : thisYear
            }

            
        },
        getGraphicReport () {
            const self = this
            this.$store.dispatch('report/fetchReportMonthly', { 
                month: self.month.value,
                year: self.year.value
            })
            .then(res => {
                const data = res.data.data
                
                self.graphicReport.series  = data.series
                self.graphicReport.options = {
                    chart: {
                        height: 400,
                        type: 'bar',
                        events: {
                            click: function(chart, w, e) {
                                // console.log(chart, w, e)
                            }
                        }
                    },
                    // colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
                    plotOptions: {
                        bar: {
                        columnWidth: '45%',
                        distributed: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        show: false
                    },
                    xaxis: {
                        categories: data.categories,
                        labels: {
                            rotate: 0,
                            rotateAlways: false,
                            hideOverlappingLabels: true,
                            showDuplicates: false,
                            trim: false,
                            offsetX: 0,
                            offsetY: 0,
                            style: {
                                // colors: ['#3498db', '#2ecc71', '#f1c40f', '#e74c3c', '#9b59b6', '#34495e', '#1abc9c', '#e67e22'],
                                fontSize: '9px',
                                cssClass: 'apexcharts-xaxis-title'
                            }
                        }
                    }
                } 
            })
            .catch(err => {
                if (!err.response) {
                    // network error
                    err.response = {
                        data: {
                        message: 'Server error'
                        }
                    };
                }
                this.$vs.notify({
                title:'Opps something error',
                text: err.response.data.message,
                color:'danger'})
            })
            .finally(() => {
                this.onLoaded()
            })
        },
        onLoaded(){
            this.loaded = true;
            this.$vs.loading.close()
        },
        loading () {
            this.loaded = false;

            this.$vs.loading({
                type: 'border',
                color: 'primary',
                text: `Wait a minute, It's getting data.`
            })
        },
    },
    watch : {
        month () {
            this.getGraphicReport()
        },
        year () {
            this.getGraphicReport()
        }
    },
    created() {
        this.getCurrentTime()
        this.getGraphicReport()
    }
}
</script>
